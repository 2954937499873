body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  line-height: initial !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* pre.chatMessage {
  margin: 0px 4px;
  padding: 0px 4px;
  font-family: inherit;
  cursor: pointer;
  border-radius: 8px;
  white-space: initial;
}
pre.chatMessage:hover {
  background-color: lightblue;
} */

.menuButton {
  position: fixed;
  top: 8px;
  right: 8px;
  z-index: 1;
}

.chatMessage {
  margin: 0px 4px;
  padding: 0px 4px;
  /* font-family: inherit;
  cursor: pointer; */
  border-radius: 8px;
}

@media (max-width: 599px) {
  .header {
    display: none;
  }
  .videoContainer {
    -webkit-flex-basis: 100vw;
    -ms-flex-preferred-size: 100vw;
    flex-basis: 100vw;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
  .videoRemote, .videoLocal {
    width: 100vw;
    height: min(calc(100vh / 2), calc(100vw / 4 * 3));
    object-fit: cover;
  }
  .chat {
    position: fixed;
    height: 50vh;
    left: 0px;
    right: 0px;
    bottom: 0px;
    padding: 4px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .chatMessages {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: flex-end;
    overflow-y: auto;
  }
  .chatMessageLeft {
    text-align: flex-start;
  }
  .chatMessageRight {
    justify-content: flex-end;
  }
  .chatMessageFrom {
    display: none;
  }
  .chatNewMessage {
    background-color: rgba(0, 0, 0, .5);
  }
}
@media (min-width: 600px) {
  .header {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    height: 48px;
    display: flex;
    align-items: center;
    padding: 4px;
  }
  .videoContainer {
    position: fixed;
    top: 54px;
    left: 0px;
    width: min(calc(((100vh - 54px) / 2) / 3* 4), calc(100vw - 360px));
  }
  .videoRemote, .videoLocal {
    width: min(calc(((100vh - 54px) / 2) / 3 * 4), calc(100vw - 360px));
    max-width: 100vw;
    height: min(calc((100vh - 54px) / 2), calc((100vw - 360px) / 4 * 3));
    max-height: calc((100vh - 54px) / 2);
    object-fit: cover;
  }
  .chat {
    position: fixed;
    top: 54px;
    left: min(calc(((100vh - 54px) / 2) / 3* 4), calc(100vw - 360px));
    right: 0px;
    bottom: 0px;
    padding: 0px 4px 4px 4px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .chatMessages {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 4px;
    border: 1px solid lightgray;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    overflow-y: auto;
  }
}